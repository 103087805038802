<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h5>Create New Order</h5>
				<div class="p-fluid formgrid grid">
					<div class="field col-12 md:col-6 lg:col-6">
						<label for="deliverto">Deliver to</label>
						<AutoComplete placeholder="" id="deliverto" :dropdown="true" :multiple="false" v-model="deliverTo" :completeOnFocus="true" :suggestions="deliverToFilteredList" @complete="searchDelivertyTo($event)" field="deliver_to"/>
					</div>
					<div class="field col-12 md:col-6 lg:col-3">
						<label for="deliverydate">Delivery Date</label>
						<Calendar id="deliverydate" :showIcon="true" :showButtonBar="true" v-model="deliveryDate" dateFormat="dd/mm/yy"></Calendar>
					</div>
					<div class="field col-12 lg:col-3">
						<label for="assignto">Assign to</label>
						<Dropdown id="assignto" v-model="assignedTo" :options="users" optionLabel="name" optionValue="id" placeholder="Optional" />
					</div>
				</div>
			</div>
			<div class="card">
				<div class="p-fluid formgrid grid">
					<div class="field col-12 md:col-4">
						<label for="orderitem">Item</label>
						<AutoComplete placeholder="" id="orderitem" :dropdown="true" :multiple="false" v-model="itemName" :completeOnFocus="true" :suggestions="itemNameFilteredList" @complete="searchItems($event)" field="item_name" />
					</div>
					<div class="field col-12 md:col-6">
						<label for="orderitemnote">Note</label>
						<InputText type="text" placeholder="" v-model="itemNote"></InputText>
					</div>
					<div class="field col-12 lg:col-2">
						<label for="orderitemqty">Quantity</label>
						<InputNumber v-model="itemQty" showButtons mode="decimal"></InputNumber>
					</div>
					<div class="field col-12 md:col-4 lg:col-2 md:col-offset-4 lg:col-offset-5">
						<Button label="Add to order" class="mr-2 mb-2" @click="addItem"></Button>
					</div>
				</div>
			</div>
			<div class="card">
				<div class="grid">
					<div class="col-12"><h5 class="clearboth">Order Item List</h5></div>
					<div class="col-12">
						<DataTable :value="items" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" 
								v-model:filters="filters1" :loading="loading1" :filters="filters1" responsiveLayout="scroll"
								:globalFilterFields="['itemName']" >
						
							<template #header>
								<div class="flex justify-content-between flex-column sm:flex-row">
									<Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter1()"/>
									<span class="p-input-icon-left mb-2">
										<i class="pi pi-search" />
										<InputText v-model="filters1['global'].value" placeholder="Keyword Search" style="width: 100%"/>
									</span>
								</div>
							</template>
							<template #empty>
								No order items found.
							</template>
							<template #loading>
								Loading order items data. Please wait.
							</template>
							<Column field="itemName" header="Item Name" style="min-width:12rem">
								<template #body="slotProps">
									{{slotProps.data.itemName}}
								</template>
							</Column>
							<Column field="itemNote" header="Item Note" style="min-width:12rem">
								<template #body="slotProps">
									{{slotProps.data.itemNote}}
								</template>
							</Column>
							<Column field="itemQty" header="Item Qty" style="min-width:12rem">
								<template #body="slotProps">
									{{slotProps.data.itemQty}}
								</template>
							</Column>
							<Column field="id" style="min-width:12rem">
								<template #body="slotProps">
									<Button class="p-button-rounded p-button-text p-button-danger" icon="pi pi-trash" @click="removeItem(slotProps)" v-tooltip="'Remove Item'" />
								</template>
							</Column>
						</DataTable>
					</div>
				</div>
				<div class="grid">
					<div class="field col-12 md:col-4 lg:col-2 md:col-offset-4 lg:col-offset-5">
						<Button label="Create Order" class="mt-2 mb-2" @click="addOrder($event)"></Button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {FilterMatchMode,FilterOperator} from 'primevue/api';
	export default {
		data() {
			return {
				deliverTo: '',
				deliveryDate: new Date(),
				assignedTo: 0,
				items: [],
				itemName: '',
				itemNote: '',
				itemQty: 0,
				itemNameFilteredList: [],
				itemNameList: [],
				filters1: null,
				loading1: true,
				users: null,
				deliverToFilteredList: null,
				deliverToList: null,
			}
		},
		created() {
			let that = this
			this.axios.post('/neworder', {
				authToken: this.$store.state.sessionUnique
			}).then(function (response) {
				that.users = response.data.users
				that.users.unshift({ id: 0, name: 'None ( Optional )' })
				that.assignedTo = 0
				that.deliverToList = response.data.deliverToList
				that.itemNameList = response.data.items
				that.loading1 = false;
			});
			this.initFilters1();
		},
		methods: {
			searchItems(event) {
				console.log(event)
				if (!event.query.trim().length) {
						this.itemNameFilteredList = [...this.itemNameList];
					}
					else {
						this.itemNameFilteredList = this.itemNameList.filter((item) => {
							return item.item_name.toLowerCase().startsWith(event.query.toLowerCase());
						});
					}
			},
			searchDelivertyTo(event) {
				console.log(event)
				if (!event.query.trim().length) {
						this.deliverToFilteredList = [...this.deliverToList];
					}
					else {
						this.deliverToFilteredList = this.deliverToList.filter((item) => {
							return item.deliver_to.toLowerCase().startsWith(event.query.toLowerCase());
						});
					}
			},
			addItem() {
				if(this.itemName.item_name){
					this.items.push({ itemName: this.itemName.item_name, itemNote: this.itemNote, itemQty: this.itemQty })
				} else {
					this.items.push({ itemName: this.itemName, itemNote: this.itemNote, itemQty: this.itemQty })
				}
				
				this.itemName = ''
				this.itemNote = ''
				this.itemQty = 0
			},
			removeItem(slotProps) {
				this.items.splice(slotProps.index, 1)
			},
			initFilters1() {
				this.filters1 = {
					'global': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
					'itemName': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}
				}
			},
			clearFilter1() {
				this.initFilters1();
			},
			addOrder(event) {
				let that = this
				let currentTime = new Date().getTime()
				if(this.deliverTo === '') {
					that.$toast.add({severity:'error', summary:'Oops', detail:'Deliver To must not be empty.', life: 3000, function () {}});
				} else if(this.deliveryDate.getTime() <= currentTime) {
					that.$toast.add({severity:'error', summary:'Oops', detail:'Deliver Date can not be before now.', life: 3000, function () {}});
				} else if(this.items.length === 0) {
					that.$toast.add({severity:'error', summary:'Oops', detail:'No Items in Order.', life: 3000, function () {}});
				} else {
					if(this.deliverTo.deliver_to) { this.deliverTo = this.deliverTo.deliver_to }
					this.$confirm.require({
					target: event.currentTarget,
					message: 'All entries are correct?',
					icon: 'pi pi-exclamation-triangle',
					accept: () => {
						this.axios.put('/order', {
							deliverTo: this.deliverTo,
							deliveryDate: this.deliveryDate.toISOString().slice(0, 19).replace('T', ' '),
							assignedTo: this.assignedTo,
							items: this.items,
							authToken: this.$store.state.sessionUnique
						// eslint-disable-next-line no-unused-vars
						}).then(function (response) {
							that.$toast.add({severity:'success', summary:'Confirmed', detail:'Order entry done successfully.', life: 3000, function () {}});
							that.deliverTo = '',
							that.deliveryDate = new Date()
							that.assignedTo = 0
							that.items = []
							that.itemName = ''
							that.itemNote = ''
							that.itemQty = 0
						});
					},
					reject: () => {}
				});
				}
			}
		}
	}
</script>
